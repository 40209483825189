<route>
{
  "meta": {
    "permission": [
      "product.view_priceformulaattributes"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card flat>
      <dialattributes
        v-model="dialog"
        :to-edit="toEdit"
        @done="reload = true"
      />
      <i-table
        :title="$tc('formula', 2)"
        :headers="headers"
        app="product.priceformulaattributes"
        api="formula.attributes"
        :reload.sync="reload"
        @click:create="open()"
        @click:edit="open($event)"
      >
        <template v-slot:item.name="{ value }">
          {{ $tc(value.toLowerCase(), 1) }}
        </template>
        <template v-slot:item.attr_type="{ value }">
          {{ $tc(value.toLowerCase(), 1) }}
        </template>
      </i-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialattributes from '../../components/attributes/modal.vue'
export default {
  components: {
    dialattributes
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null,
      attributes: [],
      loading: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('type'),
          value: 'attr_type'
        },
        {
          text:
            this.formulaAttributesBtn.delete || this.formulaAttributesBtn.edit
              ? this.$t('action')
              : '',
          value:
            this.formulaAttributesBtn.delete || this.formulaAttributesBtn.edit
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    formulaAttributesBtn() {
      return {
        delete:
          this.getPermissions([`product.delete_priceformulaattributes`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`product.change_priceformulaattributes`]) ||
          this.isAdmin
      }
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
