<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('attributes', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12">
        <ValidationProvider
          vid="name"
          :name="$tc('name', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            :error-messages="errors[0]"
            v-model="attr.name"
            outlined
            color="secondary"
            :label="$tc('name', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="attr_type"
          :name="$tc('type', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            v-model="attr.attr_type"
            :items="types"
            :error-messages="errors[0]"
            outlined
            :label="$tc('type', 1)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      attr: {
        fixed: false,
        attr_type: 'Measure',
        name: ''
      },
      dialog: false,
      isLoading2: false,
      load: false,
      valid: false,
      edit: false,
      types: [
        {
          text: this.$tc('price', 1),
          value: 'Price'
        },
        {
          text: this.$tc('cost', 1),
          value: 'Cost'
        },
        {
          text: this.$tc('measure', 1),
          value: 'Measure'
        },
        {
          text: this.$tc('other', 1),
          value: 'Other'
        }
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.attr =
            this.toEdit !== null
              ? { ...this.toEdit }
              : { name: '', attr_type: '' }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          !this.edit
            ? await this.$api.formula.attributes.create({
                form: this.attr
              })
            : await this.$api.formula.attributes.edit({
                pk: this.attr.pk,
                form: this.attr
              })
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('attributes', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
